import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/tsuto/webapp/pcrecommend/src/components/layout.js";
import Image from "components/image";
import TOC from "components/toc";
import SaleLoadable from "components/saleLoadable";
import Socials from "components/socials";
import SmallBox from "components/smallBox";
import AccentBox from "components/accentBox";
import { Link, graphql } from "gatsby";
import RenderGif from "./../images/editvideo/render.gif";
export const query = graphql`
  {
    allMdx(filter: {slug: {eq: "editvideo"}}) {
      nodes {
        headings {
          depth
          value
        }
      }
    },
    cover: file(relativePath: { eq: "cover/pcselection/editvideo.png"}) { ...eyecatchImg },
    editflow: file(relativePath: { eq: "editvideo/editflow.png"}) { ...normalImg },
    rendering: file(relativePath: { eq: "editvideo/rendering.png"}) { ...normalImg },
    layer_rendering: file(relativePath: { eq: "editvideo/layer_rendering.png"}) { ...normalImg },
    before_rendering: file(relativePath: { eq: "editvideo/before_rendering.png"}) { ...normalImg },
    memory: file(relativePath: { eq: "editvideo/memory.png"}) { ...normalImg },
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`本格的な動画編集を行いたい人へ向けたパソコンの選び方`}</h1>
    <Socials {...props} mdxType="Socials" />
    <Image {...props} name="cover" alt="動画編集用パソコンの選び方" mdxType="Image" />
    <p>{`「3DCGなどの動画を作ってみたい」と考える人は多いが、具体的に何が必要で、どのように作るのかを想像するのは難しい。作りたい動画のクオリティにもよるが、基本的に動画編集というのは専門知識が必要で、かつ高価なPCを買わなければ出来ない思われがちである。`}</p>
    <p>{`しかし、知識に関してはともかく、必要なPCスペックは実はそれほど高くない。CPUに関して言うなら、Core i5程度のものでも動画制作は可能である。`}</p>
    <p>{`では安いPCでも良いのかというと、そうではなく、PCに対して投資をすればするほど、動画制作が快適に行える。先程のCPUの例で言えば、CPUを上位のものに変えればレンダリング時間が短縮され、残りの時間を他の作業に費やせる。`}</p>
    <p>{`今回は具体的な制作過程を交えつつ、どのパーツがどの作業に有効なのかを説明していく。`}</p>
    <p>{`なお、注意しておきたいこととして、一般的な感覚で動画編集といえば、iPhoneで撮影した動画を継ぎはぎし、キャプションを加えていくことで結婚式の余興の動画を作るというようなことだと思われがちである。`}</p>
    <p>{`しかし、ここでの`}<strong parentName="p">{`動画編集は複数の動画や静止画の素材を読み込んで合成し、光と物体の関係から各ドットの色を計算し。。と複雑な処理を行い、映画のCGのような本格的な映像を作っていくような作業`}</strong>{`を対象としている。`}</p>
    <SaleLoadable title="現在のパソコンセール・お買い得情報" mdxType="SaleLoadable" /><br />
    <TOC {...props} mdxType="TOC" />
    <h2 {...{
      "id": "想定する使用ソフト",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E6%83%B3%E5%AE%9A%E3%81%99%E3%82%8B%E4%BD%BF%E7%94%A8%E3%82%BD%E3%83%95%E3%83%88",
        "aria-label": "想定する使用ソフト permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`想定する使用ソフト`}</h2>
    <p>{`筆者が普段使用しているソフトということで、この記事では`}<strong parentName="p">{`After EffectsとCINEMA4Dを使用することを想定`}</strong>{`して解説をしていく。一般的な動画制作に使われるソフトウェア（編集ならFusion、3DならBlenderなど）でも、同じ考えが適用できる。`}</p>
    <p>{`一方で、Houdiniのような大量のパーティクルを扱うシミュレーションソフトでは、動画制作に対するアプローチが根本的に違うのでそれ専用のPCの組み方をする必要があり、今回の記事では対象としていないことを述べておく。`}</p>
    <h3 {...{
      "id": "動画編集ソフトの種類",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E5%8B%95%E7%94%BB%E7%B7%A8%E9%9B%86%E3%82%BD%E3%83%95%E3%83%88%E3%81%AE%E7%A8%AE%E9%A1%9E",
        "aria-label": "動画編集ソフトの種類 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`動画編集ソフトの種類`}</h3>
    <p>{`編集ソフトには主に2つの種類がある。一つ目は動画の上に特殊効果を重ねていくレイヤー方式である。これはAdobeのAffter EffectsやPremiereが該当する。`}</p>
    <p>{`二つ目は動画に効果編集用のノードをつないで最終的な出力動画を作っていくノード方式である。こちらはFusionやNukeといったソフトが該当する。`}</p>
    <p>{`本記事ではレイヤー方式を例に執筆するが、ノード方式であっても考え方は同等である。`}</p>
    <AccentBox title="代表的な動画編集ソフト" mdxType="AccentBox">
  <li><span className="bold">After Effects</span>・・・Adobeが販売する映像のデジタル合成やモーション・グラフィックス、タイトル制作などを目的としたソフトウェア。A.E.、AEなどと略される。</li>
  <li><span className="bold">Fusion</span>・・・AEと同様のデジタル合成ソフト。ハリウッド映画にも多く採用されており、ノード方式による細かいロジカルな調整が得意。</li>
  <li><span className="bold">Nuke</span>・・・Fusionと同じノード方式の映像ソフト。ディズニー映画は全てNukeが使用されている。</li>
  <li><span className="bold">CINEMA4D</span>・・・モーショングラフィックスを得意とする3DCGソフト。After Effectsとの連携が可能。</li>
  <li><span className="bold">Blender</span>・・・3DCGのモデリングやアニメーションを作成するオープンソースの無償ソフト。使い勝手はやや独特。</li>
    </AccentBox>
    <h2 {...{
      "id": "動画制作の流れと快適さを左右するパーツ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E5%8B%95%E7%94%BB%E5%88%B6%E4%BD%9C%E3%81%AE%E6%B5%81%E3%82%8C%E3%81%A8%E5%BF%AB%E9%81%A9%E3%81%95%E3%82%92%E5%B7%A6%E5%8F%B3%E3%81%99%E3%82%8B%E3%83%91%E3%83%BC%E3%83%84",
        "aria-label": "動画制作の流れと快適さを左右するパーツ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`動画制作の流れと快適さを左右するパーツ`}</h2>
    <p>{`さっそくだが、以下は簡略化した動画編集の流れと、それぞれの作業の高速化に有効なパーツである。`}</p>
    <Image {...props} name="editflow" alt="動画編集のフロー" mdxType="Image" />
    <p>{`筆者が主に使う編集ソフトはAfter Effects のため、他の編集ソフトだと違う呼ばれ方をしているかもしれないが、実際の作業として大きく変わるところはないはずである。また、3Dソフトウェアの場合はフッテージ(後述)読み込みがなくなり、編集の部分がモデリングに置き換わる。`}</p>
    <p>{`ではフローの中身を細かく見ていこう。`}</p>
    <h3 {...{
      "id": "編集ソフト起動",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E7%B7%A8%E9%9B%86%E3%82%BD%E3%83%95%E3%83%88%E8%B5%B7%E5%8B%95",
        "aria-label": "編集ソフト起動 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`編集ソフト起動`}</h3>
    <p>{`一般的なソフトの起動は長くても10秒～15秒程度で開くが、`}<strong parentName="p">{`動画の編集ソフトはPCのスペックが低いとファイルを開くのに1分以上かかることもある`}</strong>{`。`}</p>
    <p>{`これはファイルそのものが肥大化しやすいのもあるが、膨大な数の動画素材の保存先パスを解決するのに時間がかかっているのが主な要因である。ここを解消するには、`}<strong parentName="p">{`高速なストレージにファイルを保存する必要がある`}</strong>{`。`}</p>
    <h3 {...{
      "id": "フッテージ読み込み",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%95%E3%83%83%E3%83%86%E3%83%BC%E3%82%B8%E8%AA%AD%E3%81%BF%E8%BE%BC%E3%81%BF",
        "aria-label": "フッテージ読み込み permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`フッテージ読み込み`}</h3>
    <p>{`フッテージとは、未加工の動画素材のことを指す言葉である。主なものとして、mp4、mov、または連番のpngファイルなどを指す。
高度な合成を行う際は、HDRに対応し深度情報などが動画に格納されたフォーマットのOpenEXRなども使用される。`}</p>
    <p>{`これらのフッテージを最初に読み込み、編集を加えていくのだが、当然大きなファイルほど読み込みに時間がかかってしまう。`}</p>
    <p>{`また、`}<strong parentName="p">{`フッテージを読み込む速度はCPUの性能とフッテージファイルを置くストレージのI/O（読み書き）速度に依存`}</strong>{`する。`}</p>
    <h3 {...{
      "id": "編集・リアルタイム再生",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E7%B7%A8%E9%9B%86%E3%83%BB%E3%83%AA%E3%82%A2%E3%83%AB%E3%82%BF%E3%82%A4%E3%83%A0%E5%86%8D%E7%94%9F",
        "aria-label": "編集・リアルタイム再生 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`編集・リアルタイム再生`}</h3>
    <p><strong parentName="p">{`編集とリアルタイム再生は、レンダリングと並んで作業の割合を大きく占める箇所`}</strong>{`である。業界によっては【撮影】だったり【コンポジット】などと呼ばれたりする。大まかに編集と行ってしまうと括りが大きいが、要は動画の色調補正からフッテージの切り貼り、CG合成などの動画に対する操作を総称したものを指している。`}</p>
    <p>{`リアルタイムのプレビュー再生を行う時、動画データはメモリー(RAM)に保存され、一般の動画ファイルのようにストレージには保存されない。また、圧縮されていない中間状態のファイルから動画を再生するために、それだけメモリーの使用量は大きくなる。`}</p>
    <p>{`読み込むデータによるところは大きいものの、`}<strong parentName="p">{`メモリーの搭載容量が少ないとプレビュー時間が制限されてしまい快適な作業とはほど遠くなってしまう`}</strong>{`。`}</p>
    <h3 {...{
      "id": "レンダリング",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%AC%E3%83%B3%E3%83%80%E3%83%AA%E3%83%B3%E3%82%B0",
        "aria-label": "レンダリング permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`レンダリング`}</h3>
    <p>{`レンダリングとは、編集した動画を最終的なファイルとして書き出す作業のことである。ここで動画をどのように出力するかが設定できる。`}<strong parentName="p">{`主に設定する内容は画質、解像度、色深度、ビデオコーデックなどで、ここでの設定を高品質にすればするほどがレンダリング時間が長くなる`}</strong>{`。`}</p>
    <p>{`より正確に説明するなら、レンダリングとはピクセルという格子を通り抜けて画面から出てくる光の量を計算する処理である。すべてのピクセルについて、どのような色でどれくらいの明るさの光が入ってくるのかを物理的に計算している。`}</p>
    <Image {...props} name="rendering" alt="レンダリングのイメージ" mdxType="Image" />
    <p>{`レンダリングの長さがどれくらいかかるかは、光の経路の計算量に依存しており、より正確に、かつより細かいピクセルで光量を計算しようとすればするほどレンダリングの処理に時間がかかる。4Kの解像度なんかは、面積比でFullHDの4倍なので、当然レンダリングには、4倍か、設定によってはそれ以上の時間がかかる。`}</p>
    <p>{`また、編集ソフトのレンダリングは基本的に二次元平面での出力計算を行うが、3Dソフトは3次元空間での光の計算を行う。`}<strong parentName="p">{`3D空間上での光の計算は物体の表面の拡散反射や透明なマテリアルの屈折率などを考慮するため、レンダリングの複雑度とかかる時間が跳ね上がる。`}</strong>{`そのため、映画などに使われるリアルな3Dのレンダリングはレンダーファームと呼ばれる大量のPC群で平行にレンダリングされる事がある。`}</p>
    <Image {...props} name="layer_rendering" caption="レイヤー形式のレンダリング" mdxType="Image" />
    <Image {...props} name="before_rendering" caption="レンダリング前の3Dシーン" mdxType="Image" />
    <img src={RenderGif} style={{
      width: '100%',
      marginTop: '10px'
    }} />
    <div className="center">レンダリング中</div>
    <h2 {...{
      "id": "動画編集に必要なパソコンのスペックは？",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E5%8B%95%E7%94%BB%E7%B7%A8%E9%9B%86%E3%81%AB%E5%BF%85%E8%A6%81%E3%81%AA%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3%E3%81%AE%E3%82%B9%E3%83%9A%E3%83%83%E3%82%AF%E3%81%AF%EF%BC%9F",
        "aria-label": "動画編集に必要なパソコンのスペックは？ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`動画編集に必要なパソコンのスペックは？`}</h2>
    <p>{`では動画制作における基本処理の流れと、それぞれの処理に必要なパーツの理解を深めたところで、実際にどれぐらいの性能が良いかを見ていこう。`}</p>
    <h3 {...{
      "id": "CPUは最初にケチらないように",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#CPU%E3%81%AF%E6%9C%80%E5%88%9D%E3%81%AB%E3%82%B1%E3%83%81%E3%82%89%E3%81%AA%E3%81%84%E3%82%88%E3%81%86%E3%81%AB",
        "aria-label": "CPUは最初にケチらないように permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`CPUは最初にケチらないように`}</h3>
    <p>{`動画制作の肝であるレンダリングや、フッテージのエンコード、デコードに関わるパーツである。CPUに良いものを使うことで、出力時のレンダリングや動画素材の読み込みを短縮できる。`}</p>
    <p>{`テキストテロップを追加する、フッテージを切り貼りする、くらいのシンプルな編集であればそこまでレンダリング時間には影響しないが、`}<strong parentName="p">{`エフェクト多用後やハイポリゴンモデリングのレンダリングはCPUの馬力が物を言う`}</strong>{`。作品が完成に近づくにつれ次第に重くなり、編集時間が間延びしていくことは避けたいところである。`}</p>
    <p>{`また、CPUは世代ごとにチップセットが違うため、他のパーツと違って上位のものに換装することができない。そのため、`}<strong parentName="p">{`PCを買うための予算が厳しい場合でも、CPUだけはケチらないほうが幸せになれるだろう`}</strong>{`。他のパーツはあとから追加すればなんとかなるが、CPUばかりはマザーボードから取っ替えることになる。`}</p>
    <p>{`下記のCPU比較表などを参考に決めればよいだろう。`}</p>
    <SmallBox type="link" mdxType="SmallBox">
  <Link to="/cpu/" mdxType="Link">CPU性能比較表</Link>
    </SmallBox>
    <p>{`コストパフォーマンスが高い範囲でハイスペックなCPUを搭載するのが現実的な解ではないかと思われる。`}</p>
    <p>{`また、コア数とクロック数のどちらを重視すべきか、という疑問があるかも知れないが、これはソフトウェアによってマルチスレッドの最適化の度合いに差があるため、一概に答えられない。が、一般的には、`}<strong parentName="p">{`レンダリングのスピードを重視するのであればコア数が多いもの`}</strong>{`を、`}<strong parentName="p">{`快適なリアルタイム処理やプレビュー再生にこだわるのであればクロック数を重視する`}</strong>{`のがよいと言われている。参考までに。`}</p>
    <h3 {...{
      "id": "SSDは必ず搭載すべし",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#SSD%E3%81%AF%E5%BF%85%E3%81%9A%E6%90%AD%E8%BC%89%E3%81%99%E3%81%B9%E3%81%97",
        "aria-label": "SSDは必ず搭載すべし permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`SSDは必ず搭載すべし`}</h3>
    <p>{`動画制作の有無に関わらず、`}<strong parentName="p">{`最優先で導入すべきパーツである`}</strong>{`。単純にPCの起動速度が早くなるし、編集ソフトをSSDにインストールすれば、ソフト自体の起動時間も短縮できる。`}<strong parentName="p">{`動画制作はいかに時間を節約できるかが快適性につながるので、特別な理由がない限りはPCに組み込もう。`}</strong></p>
    <p>{`After Effectsではメモリが足りない際に、レンダリング結果をディスクキャッシュとして保存する機能がある。保存したレンダリング結果は、リアルタイム再生の際に読み出されるわけだが、SSDはその読み出し速度からディスクキャッシュの一時保存先として最適なパーツである。`}</p>
    <p>{`フッテージが置いてあるドライブとは別のドライブをキャッシュ保存先として指定することで、アクセスを分散し高速な処理が行えるため、メインのSSDとは別にキャッシュ用SSDを用意する人もいる。`}</p>
    <p>{`ただ、SSDは書き込み回数が限られており、あまりディスクキャッシュを多用しすぎると寿命を縮める原因にもなる。よって`}<strong parentName="p">{`比較的余裕のある容量をおすすめ`}</strong>{`する。補足すると、SSDはセルという半導体にデータを記録するが、一つのセル当たりに書き込み回数には上限がある。よって余裕を持たせることで書き込みに使われるセルを分散させることができる。`}</p>
    <p>{`余談となるが、映像記録用の4Kシネマカメラのデータ保存用にSSDが使われており、記録したSSDをそのままPCにつないで編集する、といったワークフローが一般的になってきている。未圧縮の4K動画のサイズは伊達ではなく、CinemaDNG RAWというプロ向けのフォーマットでは、`}<strong parentName="p">{`1分の動画を撮影するだけで15GBを超えてしまう`}</strong>{`こともある。`}</p>
    <p>{`このような動画を撮影する場合、SDカードやCFastといった従来のフラッシュメモリではGBあたりのコスパが悪すぎるため、SSDを直に接続できるカメラというものがニーズとして存在したりもする。`}</p>
    <p>{`そういった数々の理由から、SSDは映像編集とは切っても切れないパーツと言えるだろう。`}</p>
    <h3 {...{
      "id": "メモリは最低16GB必要。32GB程度あればおすすめ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%A1%E3%83%A2%E3%83%AA%E3%81%AF%E6%9C%80%E4%BD%8E16GB%E5%BF%85%E8%A6%81%E3%80%8232GB%E7%A8%8B%E5%BA%A6%E3%81%82%E3%82%8C%E3%81%B0%E3%81%8A%E3%81%99%E3%81%99%E3%82%81",
        "aria-label": "メモリは最低16GB必要。32GB程度あればおすすめ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`メモリは最低16GB必要。32GB程度あればおすすめ`}</h3>
    <p>{`動画制作中の快適さの多寡は概ねメモリで決まると言っても過言ではない。有ればあるだけありがたいパーツである。一般的なPCのメモリ搭載量はだいたい8GBが目安だが、`}<strong parentName="p">{`本格的な動画制作に用いるPCの場合、最低16GB、できれば32GBは用意したい`}</strong>{`。以下はAfter Effects を起動し、FullHD設定のファイルを開いた際のメモリ使用量だが、すでに7GBも持っていかれている。`}</p>
    <Image {...props} name="memory" alt="After Effectsはメモリの消費量が大きい" mdxType="Image" />
    <p>{`もちろん大量のメモリを使ってしまわないように、メモリの許容使用量は設定できるが、ここを制限してしまうとリアルタイム再生の時間が短くなってしまうなど、編集作業に影響が出てきてしまう。また、レイヤーベースの編集ソフト以下の操作をすると、レンダリング時にレイヤーのメモリ使用量が許容使用量を超えてしまう事がある。  `}</p>
    <AccentBox title="メモリを多く消費するケース" type="check" mdxType="AccentBox">
  <li className="bad">複数レイヤにまたがるような重いエフェクトを多用する</li>
  <li className="bad">大きいサイズのソースイメージを使用する</li>
  <li className="bad">カラーマネジメントを有効にする</li>
  <li className="bad">色深度や解像度を高く設定する</li>
    </AccentBox>
    <p>{`詳しくはAdobeのマニュアルページに記載されているので、気になる人はチェックしておこう。
`}<a parentName="p" {...{
        "href": "https://helpx.adobe.com/jp/after-effects/using/memory-storage1.html",
        "target": "_blank",
        "rel": "nofollow noopener"
      }}>{`After Effects のメモリ（RAM）使用について`}</a></p>
    <p>{`許容使用量を超えてしまうと、当然編集もレンダリングもできなくなってしまい、メモリ解放を行わなければならなくなる。レイヤーを編集するたびにメモリ解放を行うのは馬鹿らしいので、なるべく最初から多めのメモリを積んでおきたい。予算との兼ね合いで最初から大量のメモリを積むことが難しい場合は、`}<strong parentName="p">{`とりあえず16GB積んでおき、お金が貯まった段階で追加する事もできる`}</strong>{`。`}</p>
    <p>{`プロの制作現場では特にリアルタイム制作の時間を確保するために128GBまでメモリを積んでいることもしばしばだとか。`}</p>
    <h3 {...{
      "id": "GPUは3Dを扱うならば必須",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#GPU%E3%81%AF3D%E3%82%92%E6%89%B1%E3%81%86%E3%81%AA%E3%82%89%E3%81%B0%E5%BF%85%E9%A0%88",
        "aria-label": "GPUは3Dを扱うならば必須 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`GPUは3Dを扱うならば必須`}</h3>
    <p>{`動画制作において、GPUが効く作業は特定の箇所に限られる。After Effectsでは高速ドラフトプレビューという、OpenGLを用いた高速レンダリング機能が搭載されているため、ここで多少なりともGPUを使用する。また、レイトレーシング3Dレンダリングという、3D空間での光の当たり方などを計算する際にも使用される。`}</p>
    <p>{`他のソフトを使用する場合、そのソフトがOpenGLなどのライブラリに対応していない場合は恩恵を受けられないこともあるので、そのソフトウェアのホームページでGPUの支援機能が搭載されているかどうか必ずチェックしておこう。`}</p>
    <p><strong parentName="p">{`3D関連の作業をする予定ならば、GPUは必須`}</strong>{`と言っても良い。CINEMA4DやBlenderなどの最新の3Dソフトウェアでは、標準でGPUレンダラーが搭載されている。GPUレンダラーとは今までのレンダリングをGPUで実行する技術で、CPUほど光の計算が正確ではないにせよ、十分にきれいな絵作りを実現することができる。`}</p>
    <p>{`また、`}<strong parentName="p">{`GPUレンダリングはCPUレンダリングと比べても格段に速いので、作業中のトライ・アンド・エラーをスムーズに行うことができる。`}</strong>{`そのため、GPUレンダリングで途中途中のルックを確認し、最終成果物としてCPUレンダリングを行う、といった使い方が最近のCGレンダリングのメインストリームとなってきている。`}</p>
    <p>{`After Effectsのサードパーティ製パーティクルエフェクトや3DエフェクトなどはGPUが無いと動かなかったりするため、自分が使用する予定のプラグインの動作要件なども確認し、最適なGPUを選んでおこう。`}</p>
    <p>{`GPUもピンからキリまであるが、NVIDIAのGeForceシリーズで、予算に合うものを用意しておけば間違うことは無いはずである。GPUは高ければいいということはなく、こと3D系の作業では`}<strong parentName="p">{`GPU投資に対する作業効率のカーブは徐々に下がっていく傾向`}</strong>{`にあり、お高いQuadroやTITANを買ってもあまり恩恵を受けられないことがある。というかGeForceが普通に優秀である。`}<strong parentName="p">{`ミドルレンジ程度のコストパフォーマンスの高いGeforceを予算に合わせて選ぶ`}</strong>{`ことをおすすめする。`}</p>
    <h2 {...{
      "id": "その他考慮すべき点",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%81%9D%E3%81%AE%E4%BB%96%E8%80%83%E6%85%AE%E3%81%99%E3%81%B9%E3%81%8D%E7%82%B9",
        "aria-label": "その他考慮すべき点 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`その他考慮すべき点`}</h2>
    <p>{`他、動画制作用のパソコンを選ぶ上で参考になると思われる事柄をまとめておく。`}</p>
    <h3 {...{
      "id": "水冷",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E6%B0%B4%E5%86%B7",
        "aria-label": "水冷 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`水冷`}</h3>
    <p>{`レンダリングというのは、基本的にCPUフル稼働で行う作業である。そうなると当然、その間の編集作業はできなくなる。1~2時間で終わるレンダリングならともかく、動画の長さやファイルの重さによっては8時間近く掛かってしまい、作業時間がもったいないため、レンダリングは寝ている間に行うのが基本である。`}</p>
    <p><strong parentName="p">{`就寝場所がPCと同じ部屋の場合、ファンの唸る音でとてもじゃないが寝れたものじゃない`}</strong>{`。耳栓を調達するのは一つの手だが、根本的にファンの騒音を軽減する手段として簡易水冷キットがある。水冷とは、文字通り水でCPUを冷やす方法の事を言う。空冷と比べてファンの稼働が少なくて済み、PC稼働時の騒音を大分抑える事ができる。`}</p>
    <h3 {...{
      "id": "拡張性",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E6%8B%A1%E5%BC%B5%E6%80%A7",
        "aria-label": "拡張性 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`拡張性`}</h3>
    <p>{`メモリ然り、SSD然りだが、動画を作っていく中で更にハイスペック、ハイパフォーマンスな環境にしたいという欲求は必ず湧いてくる。そのときに、PCの内部スペースが足りなくてGPUが追加できなかったり、5インチベイの数が足りなくてSSDが追加出来なかったりする。`}</p>
    <p>{`そして「ああ、あのときもう少し大きなPCケースにしておけば…」と悔やむことがある。そういった後悔をするぐらいならば、多少のPCの大きさには目を瞑り、可能な限りの拡張性を確保しておくほうが懸命である。`}</p>
    <p>{`また拡張性の観点からもスペックの観点からも`}<strong parentName="p">{`ノートパソコンではなくデスクトップパソコンを購入するのが基本`}</strong>{`である。`}</p>
    <p>{`拡張性については下記で詳しく述べているので参考にして欲しい`}</p>
    <SmallBox type="link" mdxType="SmallBox">
      <Link to="/extention/" mdxType="Link">パソコン購入時に考えるべき拡張性の勘所</Link>
    </SmallBox>
    <h3 {...{
      "id": "RAID",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#RAID",
        "aria-label": "RAID permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`RAID`}</h3>
    <p>{`何度も述べているように、動画制作では作業速度が非常に重要である。そのうちの一つ、動画の読み込み速度を高速化する手段にストライピングがある。自作PCに詳しい人なら、RAID0と呼んだ方が通りが良いかもしれない。これは複数のストレージを1つのストレージとして扱う技術で、書き込み・読み込み時の負荷を分散することによりデータの転送を高速化する事ができる。`}</p>
    <p>{`一方で、複数のストレージのうち1つでも故障してしまうとすべてのデータの取り出しができなくなってしまう、諸刃の剣でもある。リスクは有るが、ストレージを追加すればするほど高速化できるので、定期的なデータのバックアップを保証できるのであれば採用するのも悪くないかもしれない。`}</p>
    <p>{`とはいえ、なにかの拍子にバックアップが止まってデータが全滅しました、なんてことが起きたら目も当てられないので、動画制作の現場ではRAID 5という、複数ストレージの接続による高速化とデータの復元可能性を担保した構成が使われる事が多い。気になった人はこちらも調べてみるといいだろう。`}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      